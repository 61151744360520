import "bootstrap/dist/css/bootstrap.min.css"
import './assets/fonts/fonts.css';
import './assets/css/style.css';
import './assets/css/menu.css';
import './assets/css/font-awesome.css';
import './assets/css/responsive.css';
import './assets/css/develop.css';
import 'bootstrap';
import store from "./store"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App);
app.use(Notifications)
app.config.globalProperties.$store = store;
app.use(router).use(store).mount('#app')

import "bootstrap/dist/js/bootstrap.js"
console.log('usertoken',localStorage.user_token)
const token = localStorage.getItem('user_token')
if (token && token.length > 0) {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${token}`
}


