//router file
import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/views/HomePage.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404Page",
    component: () => import("@/views/404Page.vue"),
    // redirect: "/",
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
  },
  {
    path: "/organization/:orgSlug",
    name: "OrganizationPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/OrgDetailsPage.vue"),
  },
  {
    path: "/organization/:orgSlug/school/:schoolSlug",
    name: "OrgSchoolDetailsPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/OrgSchoolDetailsPage.vue"),
  },
  {
    path: "/organization/:orgSlug/school/:schoolSlug/history",
    name: "OrgSchoolHistoryPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/OrgSchoolHistoryPage.vue"),
  },
  {
    path: "/organization/:orgSlug/school_district/:schoolDistrictSlug",
    name: "OrgSchoolDistrictDetailsPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/OrgSchoolDistrictDetailsPage.vue"),
  },
  {
    path: "/organizations",
    name: "OrganizationListPage",
    // meta:{isBreadcrumbed:false},
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/OrganizationListPage.vue"),
  },
  {
    path: "/school_districts",
    name: "SchoolDistrictListPage",
    // meta:{isBreadcrumbed:false},
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SchoolDistrictListPage.vue"),
  },
  {
    path: "/schools",
    name: "SchoolsListPage",
    // meta:{isBreadcrumbed:false},
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SchoolsListPage.vue"),
  },
  {
    path: "/school/:entitySlug/history",
    name: "schoolHistoryPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/schoolHistoryPage.vue"),
  },
  {
    path: "/school/:entitySlug/login",
    name: "schoolLoginPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/EntityLoginPage.vue"),
  },
  {
    path: "/school/:entitySlug/registration",
    name: "schoolRegistrationPage",
    meta: { layout:"home",isBreadcrumbed: false },
    component: () => import("@/views/EntityRegistrationPage.vue"),
  },
  {
    path: "/plan",
    name: "PlanPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/PlanPage.vue"),
  },
  {
    path: "/payment",
    name: "PaymentPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/PaymentPage.vue"),
  },
  {
    path: "/stripe",
    name: "StripePage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/StripePage.vue"),
  },
  {
    path: "/addCoupon",
    name: "CouponPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/CouponPage.vue"),
  },
  {
    path: "/checkout",
    name: "CheckOutPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/CheckOutPage.vue"),
  },
  {
    path: "/paymentSuccess",
    name: "PaymentSuccessPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/PaymentSuccessPage.vue"),
  },
  {
    path: "/myplan",
    name: "MyPlanPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/MyPlanPage.vue"),
  },
  {
    path: "/addchild/:child",
    name: "AddChild",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/AddChild.vue"),
  },
  {
    path: "/editprofile",
    name: "EditProfile",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/EditProfile.vue"),
  },
  {
    path: "/myaccount",
    name: "MyAccount",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/MyAccount.vue"),
  },
  {
    path: "/myfavourite/:option",
    name: "MyFavourite",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/MyFavourite.vue"),
  },
  // {
  //   path: "/activity/:activityName/:activityGender",
  //   name: "ActivityGenderPage",
  //   meta: {
  //     isBreadcrumbed: false,
  //   },
  //   component: () => import("@/views/ActivityGenderPage.vue"),
  // },
  {
    path: "/school/:entityslug",
    name: "EntityDetailsPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/EntityDetailsPage.vue"),
  },
  {
    path: "/organization/:orgSlug/school/:entityslug/team/:teamSlug",
    name: "TeamDetailsPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/TeamDetailsPage.vue"),
  },

  {
    path: "/school_district/:sdslug",
    name: "SchoolDistrictDetailsPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SchoolDistrictDetailsPage.vue"),
  },
  {
    path: "/school_district/:sdslug/login",
    name: "SdLoginPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SdLoginPage.vue"),
  },
  {
    path: "/school_district/:sdslug/registration",
    name: "SdRegistrationPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SdRegistrationPage.vue"),
  },
  {
    path: "/login",
    name: "LoginPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/logout",
    name: "LogoutPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/LogoutPage.vue"),
  },
  {
    path: "/samplecaptcha",
    name: "CaptchaPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/CaptchaPage.vue"),
  },

  {
    path: "/forgotPassword",
    name: "ForgotPasswordPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/ForgotPasswordPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/changePassword",
    name: "OwnerChangePasswordPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/OwnerChangePasswordPage.vue"),
  },
  {
    path: "/change_password",
    name: "ChangePasswordPage",
    meta: {
      //layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/ChangePasswordPage.vue"),
  },
  
  {
    path: "/:ownerType/:ownerSlug/forgotPassword",
    name: "OwnerForgotPasswordPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/OwnerForgotPasswordPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/sentEmail",
    name: "OwnerSentEmailPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/OwnerSentEmailPage.vue"),
  },
  {
    path: "/sentEmail",
    name: "SentEmailPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SentEmailPage.vue"),
  },
  {
    path: "/thankYou",
    name: "ThankYouPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/ThankYouPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/dashboard",
    name: "DashboardPage",
    meta: {
      layout:"home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/DashboardPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/bracket",
    name: "BracketPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/BracketPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/orghistory",
    name: "OrgHistoryPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/OrgHistoryPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/orghistoryresult",
    name: "OrgHistoryResultPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/OrgHistoryResultPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/schedule",
    name: "SchedulePage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SchedulePage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/sdhistory",
    name: "SDHistoryPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SDHistoryPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/sdhistoryreport",
    name: "SDHistoryReportPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/SDHistoryReportPage.vue"),
  },
  {
    path: "/registration",
    name: "RegistrationPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/RegistrationPage.vue"),
  },
  {
    path: "/aboutus",
    name: "AboutUsPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/AboutUsPage.vue"),
  },
  {
    path: "/termsofservices",
    name: "TermsOfServicesPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/TermsOfServicesPage.vue"),
  },
  {
    path: "/privacypolicy",
    name: "PrivacyPolicyPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/PrivacyPolicyPage.vue"),
  },
  {
    path: "/advertise",
    name: "AdvertisementPage",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/AdvertisementPage.vue"),
  },
  {
    path: "/contactus",
    name: "ContactUs",
    meta: {
      isBreadcrumbed: false,
    },
    component: () => import("@/views/ContactUs.vue"),
  },
  {
    path: "/addeditorg",
    name: "AddEditOrgPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/AddEditOrgPage.vue"),
  },
  {
    path: "/addeditsd",
    name: "AddEditSDPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/AddEditSDPage.vue"),
  },
  {
    path: "/addeditentity",
    name: "AddEditEntityPage",
    meta: {
      layout: "home",
      isBreadcrumbed: false,
    },
    component: () => import("@/views/AddEditEntityPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/activity/history/:activityName/:activityGender",
    name: "HistoryPage",
    meta: {
      layout: "home",
      isBreadcrumbed: true,
      pageTitle: "Playoff History",
    },
    component: () => import("@/views/HistoryPage.vue"),
  },
  {
    path: "/:ownerType/:ownerSlug/activity/history/:activityName/:activityGender/:queryId/:queryText",
    name: "HistoryDataPage",
    meta: {
      layout: "home",
      isBreadcrumbed: true,
      pageTitle: "Playoff History Data",
    },
    component: () => import("@/views/HistoryDataPage.vue"),
  },
  {
    path: "/organization/:orgSlug/activity/:activityName/:activityGender",
    name: "ActivityOrgPage",
    meta: {
      layout:"home",
      isBreadcrumbed: true,
    },
    component: () => import("@/views/ActivityOrgPage.vue"),
  },
  {
    path: "/organization/:orgSlug/login",
    name: "OrgLoginPage",
    meta: {
      layout: "home",
      isBreadcrumbed: true,
    },
    component: () => import("@/views/OrgLoginPage.vue"),
  },
  {
    path: "/organization/:orgSlug/registration",
    name: "OrgRegistrationPage",
    meta: {
      layout:"home",
      isBreadcrumbed: true,
    },
    component: () => import("@/views/OrgRegistrationPage.vue"),
  },
  {
    path: "/activity/:activityName",
    name: "ActivityPage",
    meta: {
      isBreadcrumbed: true,
    },
    component: () => import("@/views/ActivityPage.vue"),
  },
];
const router = createRouter({ history: createWebHistory(), routes });
export default router;
