import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {
        uid: 0,
        displayName: "",
        photoURL: require("@/assets/images/profile.png"),
        userRole: "",
      },
      isLoggedIn: JSON.parse(localStorage.getItem("userInfo")) ? true : false,
    };
  },
  mutations: {
    login(state, data) {
      state.isLoggedIn = true;
      state.userInfo = {
        uid: data.id,
        displayName: data.full_name,
        photoURL: require("@/assets/images/profile.png"),
        userRole: data.role_id,
      };
      localStorage.setItem("userInfo", JSON.stringify(state.userInfo));
    },
    logout(state) {
      state.isLoggedIn = false;
      state.userInfo = {
        uid: 0,
        displayName: "",
        photoURL: "",
        userRole: "",
      };
      state.companyInformation = {
        id: 0,
        organization_name: "",
        name: "",
        organization_type_id: "",
        abbreviation: "",
        year_open: "",
        year_close: "",
        note: "",
        url_slug: "",
        country_name: "",
        state_name: "",
        country_id: "",
        organization_id: "",
        logo_url: "",
        banner_image_url: "",
        title: "",
        details: "",
        background_color: "",
        text_color: "",
      };
      state.planInformation = {
        id: 0,
        duration: "",
        is_active: "",
        plan_desc: "",
        plan_name: "",
        plan_url: "",
        uom: "",
        uom_type:"",
        unit_price: "",
        upper_bound: "",
        lower_bound: "",
      };
      localStorage.removeItem("userInfo");
      localStorage.removeItem("companyInformation");
      localStorage.removeItem("planInformation");
    },
    company_information(state, data) {
      state.isLoggedIn = true;
      state.companyInformation = {
        id: data.id,
        organization_name: data.organization_name,
        name: data.name,
        organization_type_id: data.organization_type_id,
        abbreviation: data.abbreviation,
        year_open: data.year_open,
        year_close: data.year_close,
        note: data.note,
        url_slug: data.url_slug,
        country_name: data.country_name,
        state_name: data.state_name,
        country_id: data.country_id,
        organization_id: data.organization_id,
        logo_url: data.logo_url,
        banner_image_url: data.logo_url,
        title: data.title,
        details: data.details,
        background_color: data.background_color,
        text_color: data.text_color,
      };
      localStorage.setItem(
        "companyInformation",
        JSON.stringify(state.companyInformation)
      );
    },
    plan_information(state, data) {
      state.isLoggedIn = true;
      state.planInformation = {
        id: data.id,
        duration: data.duration,
        is_active: data.is_active,
        plan_desc: data.plan_desc,
        plan_name: data.plan_name,
        plan_url: data.plan_url,
        uom: data.uom,
        uom_type: data.uom_type,
        unit_price: data.revenue_plan_prices[0].unit_price,
        upper_bound: data.revenue_plan_prices[0].upper_bound,
        lower_bound: data.revenue_plan_prices[0].lower_bound,
      };
      localStorage.setItem(
        "planInformation",
        JSON.stringify(state.planInformation)
      );
    },
  },
});
