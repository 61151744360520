<template>
  <header>
    <section class="topheader">
      <div class="container-fluid">
        <ul>
          <li>
            <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-instagram" aria-hidden="true"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-rss" aria-hidden="true"></i></a>
          </li>
          <li>
            <a href="#"><i class="fa fa-envelope" aria-hidden="true"></i></a>
          </li>
        </ul>
      </div>
    </section>
    <section class="bottomheader newbottomheader" >
      <div class="container-fluid">
        <div class="innerheader">
          <div class="logo org-logo">
            <a href="/"
              ><img
                v-if="owner_details.logo_url"
                :src="owner_details.logo_url"
                style="width: 100px" /><img
                src="./../../assets/images/gm-logo2-1@2x.png"
            /></a>
          </div>

          <div class="menu">
            <nav id="cssmenu" class="collapse navbar-collapse">
              <div id="head-mobile"></div>
              <div class="button" :class="addedClass"></div>
              <ul :class="appendClass" :style="style">
                <li><a href="/">Home</a></li>

                <li>
                  <a href="#">Activities</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="custom_radio">
            <input
              @change="onHeaderSearchChange($event)"
              checked
              type="radio"
              value="school"
              id="featured-1"
              name="featured"
            /><label for="featured-1">Schools</label>

            <input
              type="radio"
              @change="onHeaderSearchChange($event)"
              id="featured-2"
              value="school_district"
              name="featured"
            /><label for="featured-2">School District</label>
          </div>
          <div class="searchsec" v-if="searchString">
            <AutoSearch :type="search_type" :dataset="search_dataset" />
          </div>
          <div class="searchsec nosearchstring" v-else>
            <AutoSearch :type="search_type" :dataset="school_list" />
          </div>


          <div class="searchsec">
            <!--<div class="search-section">
              <a href="#" class="clicksearch" @click="toggleSearch"
                ><i v-show="glass" class="fa fa-search" aria-hidden="true"></i
                ><i v-show="cross" class="fa fa-times" aria-hidden="true"></i
              ></a>
              <div class="togolsearch" v-show="display">
                <div class="custom_radio">
                  <input
                    @change="onHeaderSearchChange($event)"
                    type="radio"
                    value="school"
                    id="featured-1"
                    name="featured"
                  /><label for="featured-1">Schools</label>
                  <input
                    type="radio"
                    @change="onHeaderSearchChange($event)"
                    id="featured-2"
                    value="school_districts"
                    name="featured"
                  /><label for="featured-2">School District</label>
                </div>
                <div style="position: relative; margin-top: 18px">
                  <AutoSearch :type="search_type" :dataset="search_dataset" />
                </div>
              </div>
            </div>-->
            <div class="togolprofile" v-if="$store.state.isLoggedIn">
              <img src="./../../assets/images/profile.png" />
              <div class="togoldropdown">
                <ul>
                 
                  <li>
                    <a
                      v-if="
                        logged_in_user_role != 15 &&
                        logged_in_user_role != 4 &&
                        logged_in_user_role != 10 &&
                        logged_in_user_role != 14
                      "
                      href="javascript:void(0)"
                      @click="goToMyDashboard()"
                      >My Account</a
                    >
                  </li>
                  <li>
                    <a
                      href="/change_password"
                      >Change Password</a
                    >
                  </li>
                  <li>
                    <a href="javascript:void(0)" @click="logout()">Logout</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="rightbutlo" v-if="!$store.state.isLoggedIn">
            <a href="/login" class="btn btn-success" style="margin: 0 3px 0 3px"
              >Login</a
            >
          </div>
        </div>
      </div>
    </section>
  </header>
</template>

<script>
import AutoSearch from './components/AutoSearch'
import axios from 'axios'
// import store from '@/store/index.js';

// import {useStore} from "vuex";
// var store;
export default {
  name: 'MainHeader',
  components: {
    AutoSearch
  },
  watch: {
    $route: 'changedRouter'
  },
  setup () {
    // this.$store = useStore();
    // console.log(this.$testGlobal)
  },
  data () {
    return {
      // $store : store,
      displayLogo: false,
      orgName: '',
      activityName: '',
      logged_in_user_email: '',
      logged_in_user_name: '',
      logged_in_user_role: '',
      logged_in_user_id: '',
      display: false,
      glass: true,
      cross: false,
      getWidth: '',
      addedClass: '',
      appendClass: '',
      style: '',
      subTree: '',
      search_type: 'school',
      school_list:[],
      school_search_dataset: [],
      schooldistrict_search_dataset: [],
      organization_search_dataset: [],
      search_dataset: [],
      ownerType: localStorage.user_owner_type,
      ownerSlug: localStorage.user_owner_slug,
      company_info: localStorage.user_organization_details,
      owner_details: [],
      searchString:false
    }
  },
  methods: {
    getSchoolList(){
      this.school_list = []
      axios
          .get(
            `https://api.tad.jeffditoro.com/list-all?types=entities&entity_type=school`
          )
          .then(response => {
            this.school_list = response.data.data.entities
            console.log('school_list', this.school_list)
          })
    },
    getCompanyInformation () {
      this.owner_details = []
      axios
        .get(
          `https://api.tad.jeffditoro.com/owner_info/ownerType/${this.ownerSlug}`
        )
        .then(response => {
          this.owner_details = response.data.data
          this.organization_activities = response.data.data.activities

          console.log('owner_details', this.owner_details)
        })
    },
    onHeaderSearchChange (event) {
      //alert(event)
      this.search_dataset = []
      this.school_search_dataset = []
      this.schooldistrict_search_dataset = []
      this.organization_search_dataset = []
      var data = event.target.value
      this.search_type = data.toString()
      this.searchString = true
      this.globalSearch(event)
    },
    globalSearch (event) {
      this.search_dataset = []
      this.school_search_dataset = []
      this.schooldistrict_search_dataset = []
      this.organization_search_dataset = []
      let searchText = event.target.value
      if (searchText.trim().length < 3) {
        return
      }
      if (this.search_type == 'school') {
        searchText = ''
        this.search_dataset = []
        this.school_search_dataset = []
        axios
          .get(
            `https://api.tad.jeffditoro.com/list-all?types=entities&entity_type=school&search=${searchText}`
          )
          .then(response => {
            this.school_search_dataset = response.data.data.entities
            this.search_dataset = this.school_search_dataset
            console.log('school_search_dataset', this.search_dataset)
          })
      }
      if (this.search_type == 'school_district') {
        searchText = ''
        this.search_dataset = []
        this.schooldistrict_search_dataset = []
        axios
          .get(
            `https://api.tad.jeffditoro.com/list-all?types=school_districts&search=${searchText}`
          )
          .then(response => {
            this.schooldistrict_search_dataset = response.data.data.school_districts
            this.search_dataset = this.schooldistrict_search_dataset
            console.log('schooldistrict_search_dataset', this.search_dataset)
          })
      }
      if (this.search_type == 'organization') {
        searchText = ''
        this.search_dataset = []
        this.organization_search_dataset = []
        axios
          .get(
            `https://api.tad.jeffditoro.com/list-all?types=entities&entity_type=organizations&search=${searchText}`
          )
          .then(response => {
            this.organization_search_dataset = response.data.data.entities
            this.search_dataset = this.organization_search_dataset
            console.log('organization_search_dataset', this.search_dataset)
          })
      }
    },
    toggleSearch () {
      this.display = !this.display
      this.glass = !this.glass
      this.cross = !this.cross
    },
    changedRouter () {
      if (this.$route.name == 'OrganizationPage') {
        this.displayLogo = true
      } else {
        this.displayLogo = false
      }
      if (this.$route.params.orgName) {
        this.orgName = this.capitalizeWords(this.$route.params.orgName)
      }
      if (this.$route.params.activityName) {
        this.activityName = this.capitalizeWords(
          this.$route.params.activityName
        )
      }
    },
    capitalizeWords (str) {
      return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    logout () {
      this.$store.commit('logout')
      localStorage.removeItem('user_email')
      localStorage.removeItem('user_name')
      localStorage.removeItem('user_role')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_owner_type')
      localStorage.removeItem('user_owner_slug')
      localStorage.removeItem('user_token')
      localStorage.removeItem('companyInformation')
      this.$router.push('/login').catch(() => {})
    },
    goToMyDashboard () {
      window.location.href =
        'https://admin.tad.jeffditoro.com/myadmin/' +
        localStorage.getItem('user_id') +
        '/' +
        localStorage.getItem('token')
    }
  },
  mounted () {
    //this.getCompanyInformation()
    this.getSchoolList()
    console.log(
      'localStorage header org',
      localStorage.user_organization_details
    )
  },
  created () {
    //console.log('layout',this.$route.meta.layout)
    this.logged_in_user_email = localStorage.user_email
    this.logged_in_user_name = localStorage.user_name
    this.logged_in_user_role = localStorage.user_role
    this.logged_in_user_id = localStorage.user_id
    this.getWidth = parseInt(screen.width)
    console.log(this.getWidth)
    if (this.getWidth <= 991) {
      this.addedClass = 'menu-opened'
      this.appendClass = 'open'
      this.style = 'display: block;'
      this.subTree = 'has-sub'
    }
    console.log('company info', localStorage.companyInformation)
    //this.onHeaderSearchChange()

    // console.log(this.logged_in_user_email)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.org-logo {
  background-color: #609cd3;
  color: #fff;
  width: 100px;
  height: 76px;
  font-size: 25px;
  text-align: center;
  line-height: 78px;
  border-radius: 5%;
}
</style>
