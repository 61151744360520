<template>
     <footer>
        <div class="container-fluid">
        <ul>
        <li><a href="/aboutus">About Us</a></li>
        <li><a href="/termsofservices">Terms of Service</a></li>
        <li><a href="/privacypolicy">Privacy Policy </a></li>
        <li><a href="/advertise">Advertisement</a></li>
        <li><a href="/contactus">Contact Us</a></li>
        </ul>
        </div>
      </footer>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
