<template>
  <div class="landing">
    <section class="landingbanner">
      <div class="container">
        <section class="landingheader">
          <div class="landinglogo">
            <a href="/home"
              ><img src="./../assets/images/gm-logo2-1@2x.png"
            /></a>
          </div>
          <!--<div class="menu menu-landing">
            <nav id="cssmenu" class="collapse navbar-collapse">
              <div id="head-mobile"></div>
              <div class="button" :class="addedClass"></div>
              <ul :class="appendClass" :style="style">
                <li><a href="/home">Home</a></li>
                <li><a href="/activity/basketball/">Basketball</a></li>
                <li>
                  <a href="#">Basketball</a>
                  <ul>
                    <li><a href="/activity/basketball/boys">Boys</a></li>
                    <li><a href="/activity/basketball/girls">Girls</a></li>
                  </ul>
                </li>
                <li :class="subTree">
                  <a href="#">Football</a>
                  <ul>
                    <li><a href="/activity/football/boys">Boys</a></li>
                    <li><a href="/activity/football/girls">Girls</a></li>
                  </ul>
                </li>
                <li :class="subTree">
                  <a href="#">Six Man</a>
                  <ul>
                    <li><a href="/activity/sixman/boys">Boys</a></li>
                    <li><a href="/activity/sixman/girls">Girls</a></li>
                  </ul>
                </li>
                <li :class="subTree">
                  <a href="#">Eight Man</a>
                  <ul>
                    <li><a href="/activity/eightman/boys">Boys</a></li>
                    <li><a href="/activity/eightman/girls">Girls</a></li>
                  </ul>
                </li>
                <li><a href="#"> Track & Field</a></li>
              </ul>
            </nav>
          </div>-->
          <div class="togolprofile" v-if="$store.state.isLoggedIn">
            <img src="./../assets/images/profile.png" />
            <div class="togoldropdown">
              <ul>
                
                <li>
                  <a
                    v-if="
                      logged_in_user_role != 15 &&
                      logged_in_user_role != 4 &&
                      logged_in_user_role != 10 &&
                      logged_in_user_role != 14
                    "
                    href="javascript:void(0)"
                    @click="goToMyDashboard()"
                    >My Account</a
                  >
                </li>
                <li>
                  <a
                    href="/change_password"
                    >Change Password</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0)" @click="logout()">Logout</a>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="!$store.state.isLoggedIn">
            <a href="/login" class="loginbut">Login</a>
            <a href="/registration" class="loginbut">Sign Up</a>
          </div>
        </section>
        <section class="landingbannerinner">
          <div class="row">
            <div class="col-md-5">
              <h1>We Bring Your Athletics History and Data to Life</h1>
              <p>
                We help you move your athletics history from the file drawer to
                online whether you are a state organization, school district or
                school. The resulting webpages can be a money earner or simply a
                public space to display achievements.
              </p>
              <a href="/organization/uil/" class="sitedemo">View Site Demo</a>
              <a href="/contactus" class="contact">Contact Us</a>
            </div>
            <div class="col-md-7">
              <div class="whitebg">
                <img src="./../assets/images/laptop_slideshow.gif" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <section class="bodycont">
      <section class="development">
        <div class="container">
          <h2>Who We Serve</h2>
          <div class="row">
            <div class="col-md-5">
              <div class="developmentpic">
                <img src="./../assets/images/tad-whitelogo-trans-2@2x.png" />
              </div>
              <p>
                Grace Media LLC launched The Athletics Website (TAD) in January
                2010.
              </p>
            </div>
            <div class="col-md-7">
              <div class="row">
                <div class="col-md-6">
                  <div class="developmenttext">
                    <h3>Organizations</h3>
                    <p>
                      Talk to us about customizing TheAthleticsDepartment.com
                      pages to create archival webpages for your athletics
                      organization.
                    </p>
                    <a href="/organizations">View Org Demo</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="developmenttext">
                    <h3>School Districts</h3>
                    <p>
                      You don't have to rely on a state organization to archive
                      your history. We can create web pages to archive and
                      display playoffs history, honors, coaching lists/changes,
                      college signings and more.
                    </p>
                    <a href="school_districts">View District Demo</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="developmenttext">
                    <h3>Schools</h3>
                    <p>
                      Individual schools or even individual school teams can
                      abandon PDFs and create a digital archive to be displayed
                      online.
                    </p>
                    <a href="/schools">View Schools Demo</a>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="developmenttext">
                    <h3>League Statistics</h3>
                    <p>
                      We provide a place to report team stats and then produce
                      weekly league statistical reports as well as a final
                      season report that includes honors and scores in addition
                      to final statistics and standings. Sports currently
                      offered are football, baseball and softball.
                    </p>
                    <a href="#">View League Demo</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="landingbot">
            <div class="landleftpic">
              <img src="./../assets/images/rectangle@1x.png" />
            </div>
            <div class="landrightcont">
              <p>
                We spent more than 10 years maintaining Texas high school
                athletics playoff results and honors as well as migrating year
                of results into one database for TheAthleticsDepartment.com
                (TAD).
              </p>
              <p>
                Now is the time to produce the site for those earning all those
                playoff wins and honors. TAD serves as a demo site for what we
                can do for your sports data needs.
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
    <footer>
      <div class="container-fluid">
        <img src="./../assets/images/footer-logo@2x.svg" /> © Grace Media LLC.
        TheAthleticsDepartment.com is a Grace Media Publication.
      </div>
    </footer>
  </div>
</template>

<script>
//import AutoSearch from './layouts/components/AutoSearch'
import axios from 'axios'
export default {
  name: 'HomePage',
  data () {
    return {
      role_id: localStorage.getItem('user_role'),
      logged_in_user_email: localStorage.getItem('user_email'),
      logged_in_user_id: localStorage.getItem('user_id'),
      displayLogo: false,
      orgName: '',
      activityName: '',
      logged_in_user_name: '',
      logged_in_user_role: '',
      display: false,
      glass: true,
      cross: false,
      getWidth: '',
      addedClass: '',
      appendClass: '',
      style: '',
      subTree: '',
      search_type: '',
      school_search_dataset: [],
      schooldistrict_search_dataset: [],
      organization_search_dataset: []
    }
  },
  methods: {
    onHeaderSearchChange (event) {
      var data = event.target.value
      this.search_type = data.toString()
      console.log(this.search_type)
      if (this.search_type == 'school') {
        this.school_search_dataset = []
        axios
          .get(
            `https://api.tad.jeffditoro.com/list-all?types=entities&entity_type=school&search=`
          )
          .then(response => {
            this.school_search_dataset = response.data.data
            console.log('school_search_dataset', this.school_search_dataset)
          })
      }
      if (this.search_type == 'school_districts') {
        this.schooldistrict_search_dataset = []
        axios
          .get(
            `https://api.tad.jeffditoro.com/list-all?types=school_districts&search=`
          )
          .then(response => {
            this.schooldistrict_search_dataset = response.data.data
            console.log(
              'schooldistrict_search_dataset',
              this.schooldistrict_search_dataset
            )
          })
      }
      if (this.search_type == 'organization') {
        this.organization_search_dataset = []
      }
    },
    toggleSearch () {
      this.display = !this.display
      this.glass = !this.glass
      this.cross = !this.cross
    },
    changedRouter () {
      if (this.$route.name == 'OrganizationPage') {
        this.displayLogo = true
      } else {
        this.displayLogo = false
      }
      if (this.$route.params.orgName) {
        this.orgName = this.capitalizeWords(this.$route.params.orgName)
      }
      if (this.$route.params.activityName) {
        this.activityName = this.capitalizeWords(
          this.$route.params.activityName
        )
      }
    },
    capitalizeWords (str) {
      return str
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    logout () {
      this.$store.commit('logout')
      localStorage.removeItem('user_email')
      localStorage.removeItem('user_name')
      localStorage.removeItem('user_role')
      localStorage.removeItem('user_id')
      localStorage.removeItem('user_owner_type')
      localStorage.removeItem('user_owner_slug')
      localStorage.removeItem('user_token')
      localStorage.removeItem('companyInformation')
      this.$router.push('/login').catch(() => {})
    },
    goToMyDashboard () {
      window.location.href =
        'https://admin.tad.jeffditoro.com/myadmin/' +
        localStorage.getItem('user_id') +
        '/' +
        localStorage.getItem('token')
    }
  },
  created () {
    this.logged_in_user_email = localStorage.user_email
    this.logged_in_user_name = localStorage.user_name
    this.logged_in_user_role = localStorage.user_role
    this.logged_in_user_id = localStorage.user_id
    this.getWidth = parseInt(screen.width)
    console.log(this.getWidth)
    if (this.getWidth <= 991) {
      this.addedClass = 'menu-opened'
      this.appendClass = 'open'
      this.style = 'display: block;'
      this.subTree = 'has-sub'
    }

    console.log(this.logged_in_user_email)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-landing #cssmenu > ul > li > a {
  padding: 23px 0;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
}
</style>
