<template>
  <div>
    <div style="">
      <div style="width: 100%">
        <div class="auto-search-container">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input
            type="text"
            class="np-input-search"
            v-model="inputSearchText"
            placeholder="Search"
            autocomplete="off"
            v-on:keyup="searchNames"
          />
        </div>
      </div>
      <div class="np-result-container">
        <div class="search-result-section" v-if="filteredResult.length > 0">
          <div
            v-for="(result, resultIndex) in filteredResult"
            :key="resultIndex"
          >
            <div class="np-result-item" @click="showCharacterDetails(result)">
              <div class="np-ib" style="width: 50px">
                <img :src="result.url" class="np-avatar" />
              </div>
              <div class="np-ib np-text-container">
                <div>
                  <a :href="`/${type}/` + result.url_slug"><span class="entityname">{{ result.name }}</span> - <span class="cityname">{{ result.city_name }},{{  result.state_name}}</span></a>
                </div>
                <div class="np-result-description">
                  {{ result.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="np-details"
      style="width: 500px; display: inline-block !important"
      v-if="characterDetails != null"
    >
      <div v-if="characterDetails != null">
        <div>
          <img :src="characterDetails.url" class="np-result-details-img" />
        </div>
        <div class="np-result-details-title">
          {{ characterDetails.name }}
        </div>
        <div class="np-result-details-description">
          {{ characterDetails.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoSearchMenu',

  props: {
    dataset: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      inputSearchText: '',
      filteredResult: [],
      input: '',
      output: ''
    }
  },
  methods: {
    convertToUrl () {
      this.input = this.input
        .toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, `-and-`) // & to and
        .replace(/--/g, `-`) // -- to -
      this.output = this.input
    },
    searchNames () {
      this.filteredResult = []
      if (
        this.inputSearchText == null ||
        (this.inputSearchText != null && this.inputSearchText.length === 0)
      ) {
        this.filteredResult = []
        this.characterDetails = null
      } else {
        if (
          this.dataset &&
          this.dataset.length > 0 &&
          this.inputSearchText.length >= 1
        ) {
          console.log('here')
          this.dataset.forEach(character => {
            const searchHasCharacterName =
              character.name &&
              character.name
                .toLowerCase()
                .includes(this.inputSearchText.toLowerCase())

            if (searchHasCharacterName) {
              this.filteredResult.push(character)
            }
          })
        }
      }
    },
    showCharacterDetails (result) {
      this.characterDetails = result
    }
  },

  watch: {
    type (val) {
      console.log(val)
      this.filteredResult = []
      this.inputSearchText = ''
    }
  },
  created () {
    console.log(this.dataset)
  }
}
</script>

<style scoped></style>
