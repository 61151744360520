<template>
   <notifications  position="top right" class="tadnotification" width="300px">
   <template #body="props">
    <div class="tadnotification">
      <p class="title">
        {{ props.item.title }}
      </p>
      <button class="close" @click="props.close">
        <i class="fa fa-fw fa-close"></i>
      </button>
      <div v-html="props.item.text"/>
    </div>
  </template>
</notifications>
  <section id="container">
  <div class="body-container">
    <template v-if="$route.meta.layout == 'home'">
        <router-view/>
    </template>
    <template  v-if="$route.meta.layout != 'home'">
        <MainHeader />
        <router-view/>
        <MainFooter/>
    </template>
    </div>
  </section>
</template>

<script>
import MainHeader from './views/layouts/MainHeader.vue'
import MainFooter from './views/layouts/MainFooter.vue'

export default {
  name: 'App',
  components: {
    MainHeader,
    // MainBody,
    MainFooter
  },
  watch: {
    '$route': 'changedRouter'
  },
  methods: {
    changedRouter() {
      console.log(this.$route)
    }
  }
}
</script>

<style>
</style>
